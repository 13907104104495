import { Box, BoxProps, Link } from '@mui/material';
import NextLink from 'next/link';
import AppLogoMini from 'public/assets/icons/logo-mini.svg';
import AppLogo from 'public/assets/icons/logo.svg';
import { forwardRef } from 'react';
export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
  isMini?: boolean;
}
const Logo = forwardRef<HTMLDivElement, LogoProps>(({
  disabledLink = false,
  sx,
  isMini
}, ref) => {
  const logo = <>
        {isMini ? <Box sx={{
      my: 2
    }}>
            <AppLogoMini height={40} width={90} />
          </Box> : <AppLogo height={50} width={156} />}
      </>;
  if (disabledLink) {
    return logo;
  }
  return <Link component={NextLink} href="/" sx={{
    display: 'contents'
  }}>
        {logo}
      </Link>;
});
export default Logo;